import "./footer.css";

function Footer(){
    return <footer>
        <p className="footer">
        Desarrollado por AppBetec
        </p>
    </footer>
}

export default Footer;