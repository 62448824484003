import React from 'react';
import ReactDOM from 'react-dom/client';
import Saudacao from './components/saudacao/saudacao.jsx';
import Footer from './components/footer/footer.jsx';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <>
      
      <h1 > Paraná Country Club </h1>
      <h2 > TÉRMINOS Y CONDICIONES DE USO </h2>
      <h3>Última actualización: 14/12/2024</h3>
<h4>1. Aceptación de los Términos</h4>
<p>Al descargar, instalar y/o utilizar la aplicación móvil [Nombre de la Aplicación] ("la Aplicación"), el usuario ("Usted" o "Usuario") acepta cumplir y estar sujeto a estos Términos y Condiciones de Uso ("Términos"). Si no está de acuerdo con estos Términos, no debe usar la Aplicación.</p>
<h4>2. Descripción del Servicio</h4>
<p>ContryApp es una plataforma que [describir brevemente los servicios que ofrece la aplicación]. La Aplicación es proporcionada por el Consorcio de Propietarios del Paraná Country Club, con domicilio en Avda. Paraná esq. Ámsterdam Zona Residencial del Paraná Country Club de la ciudad de Hernandarias.</p>
<h4>3. Requisitos para Usar la Aplicación</h4>
<p>Para utilizar la Aplicación, debe tener al menos [edad mínima, generalmente 18 años] años de edad y tener la capacidad legal para aceptar estos Términos. Usted es responsable de garantizar que toda la información proporcionada durante el registro y uso de la Aplicación sea precisa y actualizada.</p>
<h4>4. Registro y Cuenta de Usuario</h4>
<p>Para acceder a ciertas funciones de la Aplicación, es posible que se le solicite crear una cuenta. Usted es responsable de mantener la confidencialidad de su cuenta y contraseña, así como de todas las actividades que ocurran bajo su cuenta. La Empresa no será responsable de ninguna pérdida o daño que resulte de su incumplimiento de esta obligación.</p>
<h4>5. Uso Permitido</h4>
<p>El Usuario se compromete a utilizar la Aplicación únicamente con fines legales y de acuerdo con estos Términos. Está prohibido el uso de la Aplicación para cualquier actividad que infrinja los derechos de terceros, que viole la ley o que pueda dañar, deshabilitar o sobrecargar los servidores o redes conectadas a la Aplicación.</p>
<h4>6. Propiedad Intelectual</h4>
<p>Todos los derechos, títulos e intereses en y para la Aplicación, incluyendo todo el software, texto, gráficos, logos, íconos, imágenes y contenido, son propiedad exclusiva de Consorcio de Propietarios del Paraná Country Club o sus licenciantes y están protegidos por leyes de propiedad intelectual. El Usuario no adquiere ningún derecho de propiedad sobre la Aplicación ni sobre su contenido.</p>
<h4>7. Política de Privacidad</h4>
<p>El uso de la Aplicación también está sujeto a nuestra Política de Privacidad, la cual describe cómo recopilamos, utilizamos y protegemos la información personal de los usuarios. La Política de Privacidad está disponible en [enlace a la política de privacidad].</p>
<h4>8. Modificaciones de los Términos</h4>
<p>La Empresa se reserva el derecho de modificar estos Términos en cualquier momento. Cualquier modificación será efectiva inmediatamente después de su publicación en la Aplicación. Es su responsabilidad revisar periódicamente estos Términos para estar informado de cualquier cambio. El uso continuo de la Aplicación después de la publicación de modificaciones constituirá su aceptación de los Términos modificados.</p>
<h4>9. Terminación</h4>
<p>La Empresa se reserva el derecho de suspender o cancelar su cuenta y acceso a la Aplicación en cualquier momento, sin previo aviso, si se considera que ha violado estos Términos o cualquier ley aplicable, o por cualquier otro motivo a la sola discreción de la Empresa.</p>
<h4>10. Limitación de Responsabilidad</h4>
<p>La Empresa no será responsable de ningún daño directo, indirecto, incidental, especial, consecuente o punitivo que surja del uso o imposibilidad de uso de la Aplicación. Usted acepta que el uso de la Aplicación es bajo su propio riesgo.</p>
<h4>11. Ley Aplicable y Jurisdicción</h4>
<p>Estos Términos se regirán e interpretarán de acuerdo con las leyes de Paraguay. Cualquier disputa que surja en relación con estos Términos se resolverá exclusivamente en los Juzgados de la Ciudad de Hernandarias – Alto Paraná, Paraguay.</p>
<h4>12. Contacto</h4>
<p>Si tiene alguna pregunta o inquietud sobre estos Términos, por favor, comuníquese con nosotros a través de [correo electrónico de contacto] o en la dirección [dirección física de la empresa].
Consorcio de Propietarios del Paraná Country Club</p>
<p>Avda. Paraná esq. Ámsterdam Zona Residencial del Paraná Country Club de la ciudad de Hernandarias</p>

    
      <br/><br/><br/><br/>
      <Footer/>

    </>
);

